import React from "react";
import { Button, Container, Grid } from '@mui/material';
import Checkbox from "@mui/material/Checkbox";
import { useState, useEffect } from 'react';

import Logo from './interviewer-logo.svg';
import DND from './DND.svg';
import './index.css';
import { PhoneInTalk } from "@mui/icons-material";

var retellApiEndpoint = 'https://retell-main-proxy-69440b772c45.herokuapp.com'
// var retellApiEndpoint = 'http://localhost:8080'

const Phone = () => {
    const [doNotDisturbChecked, setDoNotDisturbChecked] = useState(false);
    const [showError, setShowError] = useState(false);
    const [calling, setCalling] = useState(false)

    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session');

    const onStartInterview = () => {
        if (!doNotDisturbChecked) {
            setShowError(true);
            return;
        }
        setShowError(false);

        if(calling) {
            return;
        }

        setCalling(true)

        fetch(`${retellApiEndpoint}/register-phone-call`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ sessionId: sessionId }),
        })
        .then(response => response.json())
        .then(data => {
            alert('Calling your phone now, please wait a moment...')
        })
        .catch(error => {
            console.error('Error starting phone session:', error);
            alert("Please try again in just a moment. We're dealing with a large call volume right now.")
            setCalling(false)
        });
    }

    const renderStep = () => {
        return <>
            <h3 className="phone-h3 text-center mb-8">Get ready for your interview</h3>
            <p className="phone-p text-center mb-8" style={{ marginBottom: '24px' }}>You'll receive a phone call from us. Before you start your interview, make sure:</p>
            
            <div className="card p-16 d-flex flex-row" style={{ backgroundColor: '#F9FAFB', marginBottom: '24px' }}>
                <Checkbox
                    sx={{
                        '&.Mui-checked': {
                            color: 'var(--color-primary)',
                        },
                    }}
                    style={{ marginRight: '8px' }}
                    checked={doNotDisturbChecked}
                    onChange={(e) => {
                        setDoNotDisturbChecked(e.target.checked);
                        if (e.target.checked) setShowError(false);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}/>
                <div className="d-flex flex-col">
                    <h4 className="phone-h4">
                        <img src={DND} align="center" style={{ marginRight: '8px', lineHeight: '24px' }} />
                        I've turned off "Do not disturb" or "Focus" mode
                    </h4>
                    <p className="phone-p" style={{ fontSize: '14px' }}>To make sure our call comes through, make sure you've turned off "Do not disturb" or "Focus" mode.</p>
                </div>
            </div>
        </>
    }

    const renderErrors = () => {
        return <>
            {showError && <div className="card border p-16 d-flex flex-row" style={{ backgroundColor: '#FEF3C7', borderColor: '#F9FAFB', marginBottom: '24px' }}>
                <p className="phone-p" style={{ fontSize: '14px' }}>Please check the box above.</p>
            </div>}
        </>
    }

    return (
        <div style={{ margin: 0, background: 'white', color: 'black', width: '100%', height: '100%' }}>
            <div style={{ display: 'flex', padding: '8px', borderBottom: '1px solid lightgray' }}>
                <a href="https://techinterviewer.ai" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                    <img src={Logo} />
                </a>
            </div>
            <Container style={{ marginTop: '8vh' }}>
                <div className="card border d-flex flex-col p-24" style={{ height: 'max-content' }}>
                    {
                        renderStep()
                    }
                    {
                        renderErrors()
                    }
                    <Button className={`btn ${(!doNotDisturbChecked || calling) ? 'disabled' : ''}`} startIcon={<PhoneInTalk />}
                        style={{ marginLeft: 'auto', marginRight: 'auto' }}
                        onClick={onStartInterview}>
                        {calling ? 'Calling...this may take upto 30 seconds': 'Call Number and Start Interview'}
                    </Button>
                </div>
            </Container>
            <Grid container direction="column" style={{ margin: '25px', width: '100%', height: '100%'}}>
                <Grid item xs={12}>
                </Grid>
            </Grid>
        </div>
    )
}

export default Phone;